exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blognews-js": () => import("./../../../src/pages/blognews.js" /* webpackChunkName: "component---src-pages-blognews-js" */),
  "component---src-pages-contactpage-js": () => import("./../../../src/pages/contactpage.js" /* webpackChunkName: "component---src-pages-contactpage-js" */),
  "component---src-pages-currentnews-js": () => import("./../../../src/pages/currentnews.js" /* webpackChunkName: "component---src-pages-currentnews-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-issocomments-js": () => import("./../../../src/pages/issocomments.js" /* webpackChunkName: "component---src-pages-issocomments-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-matchcenter-js": () => import("./../../../src/pages/matchcenter.js" /* webpackChunkName: "component---src-pages-matchcenter-js" */),
  "component---src-pages-oldnews-js": () => import("./../../../src/pages/oldnews.js" /* webpackChunkName: "component---src-pages-oldnews-js" */),
  "component---src-pages-placeholderimage-js": () => import("./../../../src/pages/placeholderimage.js" /* webpackChunkName: "component---src-pages-placeholderimage-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-sign-for-upnews-letter-js": () => import("./../../../src/pages/signForUpnewsLetter.js" /* webpackChunkName: "component---src-pages-sign-for-upnews-letter-js" */),
  "component---src-pages-tables-bundesliga-js": () => import("./../../../src/pages/tables/bundesliga.js" /* webpackChunkName: "component---src-pages-tables-bundesliga-js" */),
  "component---src-pages-tables-js": () => import("./../../../src/pages/tables.js" /* webpackChunkName: "component---src-pages-tables-js" */),
  "component---src-pages-tables-lalaliga-js": () => import("./../../../src/pages/tables/lalaliga.js" /* webpackChunkName: "component---src-pages-tables-lalaliga-js" */),
  "component---src-pages-tables-ligue-1-js": () => import("./../../../src/pages/tables/ligue1.js" /* webpackChunkName: "component---src-pages-tables-ligue-1-js" */),
  "component---src-pages-tables-premierleague-js": () => import("./../../../src/pages/tables/premierleague.js" /* webpackChunkName: "component---src-pages-tables-premierleague-js" */),
  "component---src-pages-tables-sapremierleague-js": () => import("./../../../src/pages/tables/sapremierleague.js" /* webpackChunkName: "component---src-pages-tables-sapremierleague-js" */),
  "component---src-pages-tables-seriea-js": () => import("./../../../src/pages/tables/seriea.js" /* webpackChunkName: "component---src-pages-tables-seriea-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-transfercenter-js": () => import("./../../../src/pages/transfercenter.js" /* webpackChunkName: "component---src-pages-transfercenter-js" */),
  "component---src-pages-videolistpage-js": () => import("./../../../src/pages/videolistpage.js" /* webpackChunkName: "component---src-pages-videolistpage-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

